import React, { useState } from 'react';
import '../App.css';
import khoa from "../Images/khoa.jpg";
import badminton from '../Images/badminton.jpg';
import cle from '../Images/cle.JPG';
import friends1 from '../Images/friends1.JPG';
import Rubik from '../Images/rubik.jpg';
import vietnam from '../Images/vietnam.JPG';
import vsa from '../Images/vsa.JPG';
import managers from '../Images/managers.png';

const AboutMe = () => {
  const interests = [
    "learning random things",
    "building passion projects",
    "going to the gym",
    "playing badminton",
    "running",
    "rock climbing", 
    "reading about game of thrones",
    "going out with friends", 
    "rewatching how i met your mother",
    "listening to indie pop",
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCaption, setSelectedCaption] = useState('');

  const imageCaptions = {
    [managers]: "me and other software engineers at the internship!",
    [badminton]: "collegiate badminton tournament!",
    [cle]: "snapshot of cleveland",
    [friends1]: "friends!",
    [Rubik]: "19.5 in a rubik's cube competition",
    [vietnam]: "vietnam snapshot",
    [vsa]: "vietnamese student association!"
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setSelectedCaption(imageCaptions[image]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setSelectedCaption('');
  };

  const smallImages = [managers, badminton, cle, friends1, Rubik, vietnam, vsa ];

  const cycleInterest = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % interests.length);
  };

  return (
    <div className="about-me-container">
      <img src={khoa} alt="Khoa Luong" className="my-picture" />
      
      <div className="about-me-section">
        <p>my name is khoa, a computer science student studying at cwru.</p>
        <p>originally from vietnam, i moved to the us to pursue higher education.</p>
        <h2> my interests </h2>
        <p>
          i am interested in startups, full stack development, and (learning) machine learning. 
        </p>
        <p>
          currently building vocally with my two friends.
        </p>
        <h2> my work experience </h2>
        <p>
          previously, i worked at:
          <ul>
            <li>software engineer intern @ <a href="https://www.mrisoftware.com" target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>mri software</a></li>
            <li>software development assistant @ <a href="https://www.jenkinslab.com" target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>jenkins lab</a></li>
            <li>currently, researcher (distributed computing) @ <a href="https://mds3-coe.com/students/" target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>sdle research center</a></li>
          </ul>
        </p>
        <h2> outside of work...</h2>
        <p>
          i am probably <span 
            onClick={cycleInterest} 
            style={{cursor: 'pointer', textDecoration: 'underline', color: 'black'}}
          >
            {interests[currentIndex]}
          </span>
        </p>
        <p style={{fontSize: '0.8em', marginTop: '-10px', textAlign: 'center'}}>
          ↑<br/>
          click here
        </p>

        <div className="small-images-container">
        {smallImages.map((image, index) => (
          <img 
            key={index} 
            src={image} 
            alt={`Small ${index}`} 
            className="small-image"
            onClick={() => openModal(image)}
          />
        ))}
      </div>
      </div>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <span className="close-icon" onClick={closeModal}>&times;</span>
          <div className="modal-content-wrapper">
            <img className="modal-content" src={selectedImage} alt="Modal" />
            <p className="modal-caption">{selectedCaption}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
