import React from 'react';
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-nav">
                <div className="footer-logo">
                    <div>/khoa-luong.com</div>
                </div>
                <ul>
                    <li>
                        <Link to="/">home</Link>
                    </li>
                    <li>
                        <Link to="/about-me">about me</Link>
                    </li>
                    <li>
                        <Link to="/projects">projects</Link>
                    </li>
                </ul>
                <div className="footer-icons">
                    <a href="https://github.com/KhoaLuongCode" target="_blank" rel="noopener noreferrer">
                        <GitHubIcon style={{ fontSize: 25, color: 'black' }} />
                    </a>
                    <a href="https://www.linkedin.com/in/khoaluongcwru/" target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon style={{ fontSize: 30, color: 'black', paddingLeft: '5px' }} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
