import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import AboutMe from './Components/AboutMe';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';  
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <main>
                    <Content />
                </main>
                <Footer />
            </div>
        </Router>
    );
}

const Header = () => {
    const location = useLocation();
    
    return (
        location.pathname === '/' && (
            <header>
                <nav>
                    <div className="nav-left">
                        <h1>khoa-luong.com</h1>
                        <p>i make things work ... sometimes</p>
                    </div>
                    <NavLinks />
                    <div className="social-icons">
                        <Typography>
                            <a href="https://github.com/KhoaLuongCode" target="_blank" rel="noopener noreferrer"
                                style={{textDecoration: 'none'}}>
                                <GitHubIcon style={{fontSize: 30, color: 'black', paddingRight: '5px'}}/>
                            </a>
                            <a href="https://www.linkedin.com/in/khoaluongcwru/" target="_blank" rel="noopener noreferrer"
                                style={{textDecoration: 'none'}}>
                                <LinkedInIcon style={{fontSize: 30, color: 'black', paddingRight: '5px'}}/>
                            </a>
                            <a href="mailto:khoa.luong@case.edu" style={{textDecoration: 'none'}}>
                                <EmailIcon style={{fontSize: 30, color: 'black'}}/>
                            </a>
                        </Typography>
                    </div>
                </nav>
            </header>
        )
    );
};

const NavLinks = () => {
    const location = useLocation();

    return (
        <div className="nav-right">
            <ul>
                {location.pathname !== '/' && (
                    <li>
                        <Link to="/">home</Link>
                    </li>
                )}
                <li>
                    <Link to="/about-me">about me</Link>
                </li>
                <li>
                    <Link to="/projects">projects</Link>
                </li>
                <li>
                     <a href="https://drive.google.com/file/d/1Izsr-YANjqTlCdof6PRAMme99LEIAVC3/view?usp=sharing">resume</a>
                </li>

            </ul>
        </div>
    );
};

const Content = () => {
    return (
        <Routes>
            <Route path="/about-me" element={<AboutMe />} />
            <Route path="/projects" element={<Projects />} />
        </Routes>
    );
};



export default App;
