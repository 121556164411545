import React from 'react';
import '../App.css'; // Make sure to import the CSS file
import GitHubIcon from '@mui/icons-material/GitHub';

const Projects = () => {
    return (
        <div className="projects-container">
            <div className="projects-section">
                <h2>my projects </h2>
                
                <div>
                    <a href="https://github.com/KhoaLuongCode/VietnameseFood_Predictor" target="_blank" rel="noopener noreferrer" style={{color: 'black', fontSize: '1.2rem'}}>
                        <b>vietnamese food predictor </b>
                    </a>
                    <p>
                        a machine learning model trained to predict your favorite vietnamese food
                    </p>
                </div>
                
                <div>
                    <a href="https://github.com/KhoaLuongCode/MatchMate" target="_blank" rel="noopener noreferrer" style={{color: 'black', fontSize: '1.2rem'}}> 
                        <b>match mate</b>
                    </a>
                    <p>
                        a platform where you can look for fellow sport players within your community
                    </p>
                </div>

                <div>
                    <a href="https://github.com/isabellemyz/vocally-project" target="_blank" rel="noopener noreferrer" style={{color: 'black', fontSize: '1.2rem'}}> 
                        <b>vocally</b>
                    </a>
                    <p>
                        building a tech interview platform 
                    </p>
                    
                </div>
                
                <div style={{marginTop: '100px'}}>
                <p>
                <a href="https://github.com/KhoaLuongCode" target="_blank" rel="noopener noreferrer">
                    <GitHubIcon style={{ fontSize: 25, color: 'black' }} /> 
                </a> .... more to come
                </p>
                </div>
            </div>
        </div>
    );
};

export default Projects;
